import Glide from "@glidejs/glide";

const GlideCarouselController = {
    init() {
        if (document.querySelector(".brand-carousel")) {
            new Glide(".brand-carousel", {
                type: "carousel",
                perView: 4,
                breakpoints: {
                    700: {
                        perView: 1.6,
                        focusAt: "center",
                    },
                },
            }).mount();
        }
        if (document.querySelector(".job-carousel")) {
            new Glide(".job-carousel", {
                type: "carousel",
                perView: 3,
                breakpoints: {
                    700: {
                        perView: 1.6,
                        focusAt: "center",
                    },
                },
            }).mount();
        }

        if (document.querySelector("#glide-life-pillar")) {
            new Glide("#glide-life-pillar", {
                type: "carousel",
                autoplay: 4000,
                center: true,
                perView: 4,
                gap: 25,
                peek: 100,
                breakpoints: {
                    960: {
                        gap: 10,
                        peek: 50,
                        perView: 2.6,
                        focusAt: "center",
                    },
                    640: {
                        perView: 1,
                        gap: 10,
                        peek: 10,
                    }
                },
            }).mount();
        }

        if (document.querySelector("#glide-life-people")) {
            new Glide("#glide-life-people", {
                type: "carousel",
                perView: 1.5,
                gap: 20,
                focusAt: 0,
                peek: {
                    before: 0,
                    after: 50,
                },
                breakpoints: {
                    960: {
                        perView: 1.2,
                        gap: 1,
                        focusAt: "center",
                    },
                    640: {
                        perView: 1,
                        peek: 10,
                        gap: 5,
                        focusAt: "center",
                    },
                },
            }).mount();
        }
        if (document.querySelector("#glide-life-team")) {
            new Glide("#glide-life-team", {
                type: "slider",
                perView: 1,
                focusAt: "center",
            }).mount();
        }
    },
};

export default GlideCarouselController;
