import Utils from "./InstantSearch/Utils";

const MobileHeaderSearcher = {
    homeSearcherContainerEl: {
        selector: "#home-instantsearch",
    },

    jobOffersSearcherContainerEl: {
        selector: "#brands-instantsearch",
    },

    brandsSearcherContainerEl: {
        selector: "#jobs-instantsearch",
    },

    mobileFormEl: {
        selector: ".mobile-search-form-js",
    },

    mobileSearchBox: {
        selector: "#mobile-search-box",
    },

    jobsPage: {
        urlName: "jobs",
        queryParamName: `${import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX}[query]`,
    },

    mobileItem: {
        event: "mobile-item-clicked",
    },

    searchBoxEl: {
        mobileSelector: "#mobile-search-box",
    },

    shouldBeInitiated() {
        return (
            !document.querySelectorAll(this.homeSearcherContainerEl.selector)
                .length &&
            !document.querySelectorAll(
                this.jobOffersSearcherContainerEl.selector
            ).length &&
            !document.querySelectorAll(this.brandsSearcherContainerEl.selector)
                .length
        );
    },

    init() {
        if (this.shouldBeInitiated()) {
            this.setListeners();
        }
    },

    setListeners() {
        $(this.searchBoxEl.mobileSelector).on("change", (ev) =>
            this.mobileInputChanged(ev)
        );
        $(this.mobileFormEl.selector).on("submit", (ev) =>
            this.mobileFormSubmitted(ev)
        );
        document.addEventListener(this.mobileItem.event, (ev) =>
            this.mobileItemClicked(ev)
        );
    },

    mobileInputChanged(ev) {
        window.searchedValue = ev.target.value;
    },

    mobileFormSubmitted(ev) {
        ev.preventDefault();
        const query = window.searchedValue;
        window.location.href = Utils.buildSearchUrl(false, query);
    },

    mobileItemClicked(ev) {
        const query = ev.detail;
        window.location.href = Utils.buildSearchUrl(false, query);
    },

    mobileHeaderSearchRun(ev) {
        const inputValue = $(this.mobileSearchBox.selector).val();
        if (inputValue) {
            window.location.href = this.buildUrl(inputValue);
        }
    },

    buildUrl(inputValue) {
        return `${window.location.origin}/${this.jobsPage.urlName}?${this.jobsPage.queryParamName}=${inputValue}`;
    },
};

export default MobileHeaderSearcher;
