import { stats as statsWidget } from "instantsearch.js/es/widgets";

export const stats = () =>
    statsWidget({
        container: "#stats",
        templates: {
            text: `
          {{^areHitsSorted}}
            {{#hasNoResults}}No results{{/hasNoResults}}
            {{#hasOneResult}}<span class="text-white inline-block bg-purple-500 rounded-full px-2 mb-6">1</span> Open Position{{/hasOneResult}}
            {{#hasManyResults}}<span class="text-white inline-block bg-purple-500 rounded-full px-2 mb-6">{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}}</span> Open Positions{{/hasManyResults}}
          {{/areHitsSorted}}
        `,
        },
    });
