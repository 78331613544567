import instantSearch from "instantsearch.js";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { searchBox } from "./widgets/SearchBox";
import { infiniteHits } from "./widgets/InfiniteHitsBrands";
import { stats } from "./widgets/BrandsStats";
import { configure } from "instantsearch.js/es/widgets";
import Utils from "./Utils";
import { newLocationsFilters } from "./widgets/NewLocationsFilters";
import { refinementComponent } from "./widgets/RefinementComponent";

const BrandsSearcher = {
    searchContainerEl: {
        selector: "#brands-instantsearch",
    },
    searchBoxEl: {
        desktopSelector: "#search-box",
    },
    searchButtonEl: {
        selector: "#search-button",
    },
    searchFormEl: {
        selector: ".search-form-js",
    },

    checkSearchContainer() {
        return document.querySelectorAll(this.searchContainerEl.selector)
            .length;
    },

    init() {
        if (this.checkSearchContainer()) {
            const searchClient = this.setUpInstantsearch();
            Utils.setDefaultSearcherListeners(searchClient);
            this.setListeners(searchClient);
        }
    },

    setListeners(searchClient) {
        $(this.searchButtonEl.selector).on("click", (ev) =>
            Utils.runSearch(ev, searchClient)
        );
        Utils.suggestionsListeners(searchClient);
        Utils.setKeyUpEnterListener(searchClient);
    },

    inputSearch(ev, searchClient) {
        const query = $(this.searchBoxEl.desktopSelector).val().trim();
        $(this.searchBoxEl.mobileSelector).val(query);
        searchClient.helper.setQuery(query).search();
    },

    setQueryParamToInput(value) {
        $(this.searchBoxEl.desktopSelector).val(value);
        if (!!value) {
            window.inputQueryParam = true;
        }
    },

    setUpInstantsearch() {
        window.instantMeiliSearch = instantMeiliSearch;
        const queryParameter = Utils.getQueryParameterFromUrl();
        this.setQueryParamToInput(queryParameter);

        const searchClient = instantSearch({
            indexName: import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX,
            searchClient: instantMeiliSearch(
                import.meta.env.VITE_MEILISEARCH_HOST,
                import.meta.env.VITE_MEILISEARCH_KEY,
                {
                    keepZeroFacets: true,
                    paginationTotalHits: 1000,
                }
            ),
            routing: true,
        });

        searchClient.addWidgets([
            infiniteHits(),
            newLocationsFilters(),
            refinementComponent({
                attribute: "language_requirements",
                componentSelector: "#required-languages",
                selectedItemCounterClass:
                    ".selected-required-languages-counter-js",
                refinementFilterJsClass: "lang-req-filter-js",
                windowInitial: "initialLanguageRequirements",
                windowSelected: "selectedLanguageRequirements",
                lastRefinement: false,
            }),
            refinementComponent({
                attribute: "industries",
                componentSelector: "#industries",
                selectedItemCounterClass: ".selected-industry-counter-js",
                refinementFilterJsClass: "industry-filter-js",
                windowInitial: "initialIndustries",
                windowSelected: "selectedIndustries",
                lastRefinement: false,
            }),
            refinementComponent({
                attribute: "contracts",
                componentSelector: "#contracts",
                selectedItemCounterClass: ".selected-contracts-counter-js",
                refinementFilterJsClass: "contract-filter-js",
                windowInitial: "initialContracts",
                windowSelected: "selectedContracts",
                lastRefinement: true,
            }),
            configure({
                disjunctiveFacetsRefinements: {
                    brand: [window.brandName],
                },
            }),
            stats(),
            searchBox(),
        ]);

        window.selectedContracts = [];
        window.selectedLocations = [];
        window.selectedIndustries = [];
        window.selectedExpertises = [];
        window.selectedLanguageRequirements = [];
        window.initialLoad = true;
        searchClient.start();

        return searchClient;
    },
};

export default BrandsSearcher;
