import "./bootstrap";
import jQuery from "jquery";
try {
    window.$ = window.jQuery = jQuery;
} catch (e) {}

import intlTelInput from "intl-tel-input";
import * as FilePond from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import ViewHandler from "./ViewHandler";
import Trix from "trix";
import.meta.glob([
    '../images/**',
    '../fonts/**',
])
window.intlTelInput = intlTelInput;
window.FilePond = FilePond;
FilePond.registerPlugin(FilePondPluginFileValidateType);
FilePond.registerPlugin(FilePondPluginFileValidateSize);
window.jobOffersIndex = import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX;
window.Trix = Trix;

ViewHandler.init();
