import { infiniteHits as infiniteHitsWidget } from "instantsearch.js/es/widgets";

export const infiniteHits = () =>
    infiniteHitsWidget({
        container: "#infinite-hits",
        escapeHTML: false,
        transformItems(hits) {
            window.searched = true;
            return hits.map((item) => ({
                ...item,
                desc: decodeEntities(item.description),
            }));
        },
        templates: {
            item: (data) => {
                return `
                    <a href='/brands/${data["brand_slug"]}/jobs/${data["id"]}'>
                        <div class="hover:bg-white bg-white md:bg-transparent rounded-xl border border-grey-400 mb-3 md:mb-6 hover:border-grey-400 hover:outline-purple-500 hover:shadow-lg p-3 md:p-6 cursor-pointer transition-all">
                            <div class="flex">
                                <div class="w-20 mr-3">
                                    <div class="shadow-md rounded-md h-12 w-12 md:h-20 md:w-20 mb-3 md:mb-0 overflow-hidden">
                                        <img src='${
                                            data["brand_logo_url"] ?? "#"
                                        }' alt="brand-logo">
                                    </div>
                                </div>
                                <div class="w-full">
                                    <h2 class="text-md md:text-xl font-semibold">
                                        ${data["title"]}
                                    </h2>
                                    <div class="inline-block text-sm mb-4">
                                        <span class="font-semibold">
                                            ${data["brand_public_name"]}
                                        </span>
                                        <span class="mx-1">
                                        •
                                        </span>

                                        ${data["locations"]
                                            .map((city) => {
                                                if (city.length > 0) {
                                                    city = city.substring(
                                                        city.indexOf(">") + 1
                                                    );
                                                    return `<span class="capitalize"> ${city} </span> `;
                                                }
                                            })
                                            .join(", ")}

                                    </div>
                                    <p class="text-sm md:block hidden">
                                            ${data["desc"]}
                                    </p>
                                    <div class="flex flex-wrap md:mt-4">
                                        ${data["skills"]
                                            .map((skill) => {
                                                return `<button class="rounded-lg inline mx-1 mb-1 bg-purple-300 text-purple-500 p-1 md:p-2 text-xs md:text-sm">
                                                        ${skill}
                                                    </button>`;
                                            })
                                            .join("")}
                                    </div>
                                    <span class="text-sm opacity-50 md:float-right mt-4 md:mt-0">
                                        ${daysAgo(
                                            data["web_published_date_days_ago"]
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </a>
                        `;
            },
        },
    });
var decodeEntities = (function () {
    var element = document.createElement("div");

    function decodeHTMLEntities(str) {
        if (str && typeof str === "string") {
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = "";
        }

        return str;
    }

    return decodeHTMLEntities;
})();

function daysAgo(date) {
    switch (date) {
        case 0:
            return "Today";
        case 1:
            return date + " day ago";
        default:
            return date + " days ago";
    }
}
