const StickyBehaviorsController = {
    init() {
        const brandHeaderSticky = document.querySelector(".brand-header");
        if (brandHeaderSticky) {
            const observer = new IntersectionObserver(
                ([e]) =>
                    e.target.classList.toggle(
                        "isSticky",
                        e.intersectionRatio < 1
                    ),
                {
                    threshold: [1],
                }
            );

            observer.observe(brandHeaderSticky);
        }
    },
};

export default StickyBehaviorsController;
