import JobOffersSearcher from "./InstantSearch/JobOffersSearcher";
import BrandsSearcher from "./InstantSearch/BrandsSearcher";
import HomeSearcher from "./InstantSearch/HomeSearcher";
import MobileHeaderSearcher from "./MobileHeaderSearcher";
import StickyBehaviorsController from "./Controllers/StickyBehaviorsController";
import ApplyFormController from "./Controllers/ApplyFormController";
import GlideCarouselController from "./Controllers/GlideCarouselController";
import SmoothScrollController from "./Controllers/SmoothScrollController";
import DataLayerController from "./Controllers/DataLayerController";
import UrlDataController from "./Controllers/UrlDataController";
import AlpineController from "./Controllers/AlpineController";

const ViewHandler = {
    init() {
        UrlDataController.init();
        AlpineController.init();
        MobileHeaderSearcher.init();
        SmoothScrollController.init();

        const { pageType } = window;

        DataLayerController.init(pageType);
        switch (pageType) {
            case "home":
                GlideCarouselController.init();
                HomeSearcher.init();
                break;
            case "jobs":
                JobOffersSearcher.init();
                break;
            case "brands":
                BrandsSearcher.init();
                StickyBehaviorsController.init();
                break;
            case "job-detail":
                ApplyFormController.init();
                break;
            case "life-at-mantu":
                GlideCarouselController.init();
        }
    },
};

export default ViewHandler;
