import { connectRefinementList } from "instantsearch.js/es/connectors";
import Utils from "../Utils";
import SimilarityController from "../../Controllers/SimilarityController";

const renderRefinementList = (renderOptions, isFirstRender) => {
    const { refine } = renderOptions;

    if (isFirstRender) {
        setUpQueryParamsFromUrl();
    }

    if (!window.countries && !window.cities) {
        Utils.buildLocationLists();
    }

    if (!window.searched) {
        return false;
    }

    window.searched = false;

    const value = $("#location").val();
    const locationInput = checkLocationsForSimilarity(value);
    if (locationInput.toLowerCase() !== value.toLowerCase()) {
        $("#location").val(locationInput);
    }

    if (locationInput) {
        if (
            window.locationInput &&
            window.locationInput.toLowerCase() === locationInput.toLowerCase()
        ) {
            addInputToTheUrl(locationInput);
            return false;
        }
        window.locationInput = locationInput;
        const country = locationInput.split(',').length === 2? locationInput.split(',')[1].trim().toLowerCase():'';
        const cityIndex = Utils.locationCityIndex(locationInput.split(',')[0], country),
            countryIndex = Utils.locationCountryIndex(locationInput.split(',')[0]);
        uncheckPreviousSearch(refine);
        let isTheSameSearchValue = false;
        if (countryIndex !== -1) {
            isTheSameSearchValue = filterByCountry(
                countryIndex,
                refine,
                isTheSameSearchValue
            );
        } else if (locationInput.split(',').length === 1) {
            isTheSameSearchValue = filterByList(
                window.cities.filter((c) => c.name.toLowerCase() === locationInput.toLowerCase()),
                refine,
                isTheSameSearchValue
            );
        } else {
            isTheSameSearchValue = filterByCity(
                cityIndex,
                refine,
                isTheSameSearchValue,
                locationInput
            );
        }

        toggleIsFirstSearch();
        initIsFirstSearch();
        addInputToTheUrl(locationInput);
    } else {
        runClearRefinementsActions(refine);
    }
};

const checkLocationsForSimilarity = (locationInput) => {
    let result = locationInput;
    const minLocationInputChars = 3;
    const cities = window.cities.map((a) => a.name + ', ' + a.country);
    const locationInputCorrect =
        locationInput && locationInput.length >= minLocationInputChars;
    if (locationInputCorrect) {
        const locations = cities.concat(window.countries).sort();
        result = checkSimilarity(locations, locationInput, cities);
        return getBestResult(result, locationInput);
    }

    return result;
};

const userInputInLocationsList = (list, userInput) => {
    const result = [];
    if (list.includes(userInput.toLowerCase())) {
        result.push({
            city: userInput,
            points: 10,
        });
    }
    return result;
};

const getFirstLocationFromSuggestions = (cities) => {
    const result = [];
    if (
        window.searchResults.length &&
        window.searchResults.length !== cities.length
    ) {
        result.push({
            city: searchResults[0].city,
            points: 10,
        });
    }
    return result;
};

const getStartsByLocation = (list, userInput) => {
    const result = [];
    const startsWith = list.filter((listItem) =>
        listItem.startsWith(userInput.toLowerCase())
    );
    if (startsWith.length) {
        const location =
            startsWith[0] === userInput.toLowerCase()
                ? userInput
                : startsWith[0];
        result.push({
            city: location,
            points: 10,
        });
    }
    return result;
};

const rateWithSimilarityScore = (list, userInput) => {
    const result = [];
    list.some((item) => {
        const similarityScore = SimilarityController.similarity(
            userInput,
            item
        );
        if (similarityScore > 0.5) {
            result.push({
                city: item,
                points: similarityScore,
            });
        }
    });
    return result;
};

const getFromHomeSuggestions = () => {
    const result = [];
    const homeSuggestions = JSON.parse(
        sessionStorage.getItem("homeSuggestions")
    );
    sessionStorage.removeItem("homeSuggestions");
    if (homeSuggestions && homeSuggestions.length) {
        result.push({
            city: homeSuggestions[0].city,
            points: 10,
        });
    }
    return result;
};

const checkSimilarity = (list, locationInput, cities) => {
    let similarLocations = getFromHomeSuggestions();
    if (similarLocations.length) return similarLocations;

    similarLocations = userInputInLocationsList(list, locationInput);
    if (similarLocations.length) return similarLocations;

    similarLocations = getFirstLocationFromSuggestions(cities);
    if (similarLocations.length) {
        return similarLocations;
    }

    similarLocations = rateWithSimilarityScore(list, locationInput);
    return similarLocations;
};

const getBestResult = (result, locationInput) => {
    if (result && result.length) {
        return result.sort((a, b) => b.points - a.points).shift().city;
    }

    return locationInput;
};

const addInputToTheUrl = (input) => {
    window.addInputToUrlTimeout = setTimeout(() => {
        appendInputToUrl(input);
    }, 1000);
};

const appendInputToUrl = (input) => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    params.set("locationInput", input);

    const nextURL =
        window.location.origin +
        window.location.pathname +
        "?" +
        params.toString();
    const nextTitle = "My new page title";
    window.history.replaceState({}, nextTitle, nextURL);
};

const setUpQueryParamsFromUrl = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let index = 0;
    let params = [];
    let param;
    while (
        (param = urlParams.get(
            `${
                import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX
            }[refinementList][locations][${index}]`
        )) !== null
    ) {
        params.push(param);
        index++;
    }

    window.refCities = params;
    setInputValue(getInputValue(urlParams));
};

const getInputValue = (urlParams) => {
    return urlParams.get(`locationInput`);
};

const setInputValue = (inputValue) => {
    $("#location").val(inputValue);
};

const uncheckPreviousSearch = (refine) => {
    const { lastLocationRefine } = window;
    if (lastLocationRefine) {
        refine(lastLocationRefine);
    }

    if (window.refCities && window.refCities.length) {
        window.refCities.forEach((refCity) => {
            refine(refCity);
        });
    }
};

const filterByCity = (
    cityIndex,
    refine,
    isTheSameSearchValue,
    searchVal = ""
) => {
    const { lastLocationRefine } = window;
    let cityObj = {};
    if (cityIndex !== -1) {
        cityObj = window.cities[cityIndex];
    } else {
        cityObj = {
            searchValue: searchVal,
        };
    }

    const { searchValue } = cityObj;
    if (window.isFirstSearch || lastLocationRefine !== searchValue) {
        refine(searchValue);
    }

    if (
        window.lastLocationRefine &&
        window.lastLocationRefine === searchValue
    ) {
        isTheSameSearchValue = true;
    } else if (
        window.refCities &&
        window.refCities.length &&
        window.refCities[0] === searchValue
    ) {
        isTheSameSearchValue = true;
    }

    window.lastLocationRefine = searchValue;
    window.refCities = [];

    return isTheSameSearchValue;
};

const toggleIsFirstSearch = () => {
    if (window.isFirstSearch) window.isFirstSearch = false;
};

const initIsFirstSearch = () => {
    if (window.isFirstSearch === undefined) window.isFirstSearch = true;
};

const filterByCountry = (countryIndex, refine, isTheSameSearchValue) => {
    const country = window.countries[countryIndex],
        citiesInCountry = Utils.findCitiesByCountry(country);
    return filterByList(citiesInCountry, refine, isTheSameSearchValue);
};

const filterByList = (cities, refine, isTheSameSearchValue) => {
    let refinedCities = [];
    cities.forEach((cityObj) => {
        const { searchValue } = cityObj;
        refine(searchValue);

        refinedCities.push(searchValue);
    });
    if (
        window.lastLocationRefine &&
        refinedCities.length === 1 &&
        window.lastLocationRefine === refinedCities[0]
    ) {
        isTheSameSearchValue = true;
    } else if (
        window.refCities &&
        window.refCities.length &&
        window.refCities === refinedCities
    ) {
        isTheSameSearchValue = true;
    }
    window.refCities = refinedCities;
    window.lastLocationRefine = undefined;

    return isTheSameSearchValue;
};

const queryParamInUrl = (queryValue) => {
    if (queryValue) {
        const urlPath = window.location.pathname + window.location.search;
        if (
            urlPath.includes(
                `${encodeURIComponent(
                    import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX + "[query]"
                )}=${encodeURIComponent(queryValue)}`
            )
        ) {
            return true;
        }
    } else {
        return true;
    }
    return false;
};

const runClearRefinementsActions = (refine) => {
    const { lastLocationRefine } = window;

    if (lastLocationRefine) {
        refine(lastLocationRefine);
    }

    if (window.refCities && window.refCities.length) {
        window.refCities.forEach((refCity) => {
            refine(refCity);
        });
    }

    window.refCities = [];
    window.lastLocationRefine = undefined;
    window.locationUrlInput = "";
    window.locationInput = "";
    if (window.addInputToUrlTimeout) clearTimeout(window.addInputToUrlTimeout);
};

const customRefinementList = connectRefinementList(renderRefinementList);

export const newLocationsFilters = () =>
    customRefinementList({
        container: document.querySelector("#refinement-list"),
        attribute: "locations",
        limit: 1000,
        operator: "or",
        sortBy: ["name:asc"],
    });
