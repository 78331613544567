import instantSearch from "instantsearch.js";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

import { searchBox } from "./widgets/SearchBox";
import { stats } from "./widgets/Stats";
import { infiniteHits } from "./widgets/InfiniteHits";
import { newLocationsFilters } from "./widgets/NewLocationsFilters";
import { refinementComponent } from "./widgets/RefinementComponent";

import Utils from "./Utils";
import DataLayerController from "../Controllers/DataLayerController";

const JobOffersSearcher = {
    searchContainerEl: {
        selector: "#jobs-instantsearch",
    },

    searchBoxEl: {
        desktopSelector: "#search-box",
        mobileSelector: "#mobile-search-box",
    },

    searchButtonEl: {
        selector: "#search-button",
    },

    searchFormEl: {
        selector: ".search-form-js",
    },

    mobileItem: {
        event: "mobile-item-clicked",
    },

    checkSearchContainer() {
        return document.querySelectorAll(this.searchContainerEl.selector)
            .length;
    },

    init() {
        if (this.checkSearchContainer()) {
            const searchClient = this.setUpInstantsearch();
            Utils.setDefaultSearcherListeners(searchClient);
            this.setListeners(searchClient);

            window.homeSearch = JSON.parse(
                sessionStorage.getItem("homeSearch")
            );
            if (window.homeSearch) {
                DataLayerController.searchDataPush();
            }
            sessionStorage.setItem("homeSearch", "false");
        }
    },

    setListeners(searchClient) {
        $(this.searchButtonEl.selector).on("click", (ev) =>
            Utils.runSearch(ev, searchClient)
        );
        document.addEventListener(this.mobileItem.event, (ev) =>
            this.mobileItemClicked(ev, searchClient)
        );
        Utils.suggestionsListeners(searchClient);
        Utils.setKeyUpEnterListener(searchClient);
    },

    mobileItemClicked(ev, searchClient) {
        ev.preventDefault();
        const query = ev.detail;
        setTimeout(() => {
            $(this.searchBoxEl.desktopSelector).val(query);
            Utils.runSearch(ev, searchClient);
        }, 500);
    },

    setQueryParamToInput(value) {
        $(this.searchBoxEl.desktopSelector).val(value);
        $(this.searchBoxEl.mobileSelector).val(value);
        if (!!value) {
            window.inputQueryParam = true;
        }
    },

    setUpInstantsearch() {
        window.instantMeiliSearch = instantMeiliSearch;

        const searchClient = instantSearch({
            indexName: import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX,
            searchClient: instantMeiliSearch(
                import.meta.env.VITE_MEILISEARCH_HOST,
                import.meta.env.VITE_MEILISEARCH_KEY,
                {
                    keepZeroFacets: true,
                    paginationTotalHits: 1000,
                }
            ),
            routing: true,
        });
        searchClient.addWidgets([
            infiniteHits(),
            newLocationsFilters(),
            refinementComponent({
                attribute: "language_requirements",
                componentSelector: "#required-languages",
                selectedItemCounterClass:
                    ".selected-required-languages-counter-js",
                refinementFilterJsClass: "lang-req-filter-js",
                windowInitial: "initialLanguageRequirements",
                windowSelected: "selectedLanguageRequirements",
                lastRefinement: false,
            }),
            refinementComponent({
                attribute: "industries",
                componentSelector: "#industries",
                selectedItemCounterClass: ".selected-industry-counter-js",
                refinementFilterJsClass: "industry-filter-js",
                windowInitial: "initialIndustries",
                windowSelected: "selectedIndustries",
                lastRefinement: false,
            }),
            refinementComponent({
                attribute: "brand_public_name",
                componentSelector: "#brands",
                selectedItemCounterClass: ".selected-brand-counter-js",
                refinementFilterJsClass: "brand-filter-js",
                windowInitial: "initialBrands",
                windowSelected: "selectedBrands",
                lastRefinement: false,
            }),
            // refinementComponent({
            //     attribute: 'expertises',
            //     componentSelector: '#expertises',
            //     selectedItemCounterClass: '.selected-expertise-counter-js',
            //     refinementFilterJsClass: 'expertise-filter-js',
            //     windowInitial: 'initialExpertises',
            //     windowSelected: 'selectedExpertises',
            //     lastRefinement: false
            // }),
            refinementComponent({
                attribute: "contracts",
                componentSelector: "#contracts",
                selectedItemCounterClass: ".selected-contracts-counter-js",
                refinementFilterJsClass: "contract-filter-js",
                windowInitial: "initialContracts",
                windowSelected: "selectedContracts",
                lastRefinement: true,
            }),
            stats(),
            searchBox(),
        ]);

        window.selectedContracts = [];
        window.selectedLocations = [];
        window.selectedIndustries = [];
        window.selectedExpertises = [];
        window.selectedBrands = [];
        window.locationUrlInput = "";
        window.selectedLanguageRequirements = [];
        window.initialLoad = true;
        searchClient.start();

        return searchClient;
    },
};

export default JobOffersSearcher;
